import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import InsideBanner from '../components/insidebanner'
import { Container, Header, Grid, Button } from 'semantic-ui-react'

const Resources = () => (
  <Layout location="resources">
    <SEO title="Resources" />
    <InsideBanner headline="Resources for children and families affected by tourette syndrome" src="/img/banners/Eisenreich.Phillies-e1523836613569.jpg" />
    <Container style={{ marginBottom: '35px' }} vertical>
      <Header as='h2' content="Resource Guides" />
      <Header as='h3' content="Helping a child with Tourette Syndrome is not difficult." /> 
      <p>Adult support staff both in and out of school come into contact with TS students outside of the classroom. This guide provides suggestions on dealing with TS behaviors for bus drivers, coaches, and other adult leaders. A little patience combined with creativity and flexibility in the learning environment can help children with Tourette Syndrome (TS) thrive and overcome their unique challenges. We have created the following Resource Guides to help you address the special needs of a child with Tourette Syndrome and related disorders.</p>
      <Grid divided='vertically'>
        <Grid.Row>
          <Grid.Column width={4}>
            <Header as='h4' content="For Teachers" className="purple-header"/>
            <p>Teachers have the critical job of finding methods that design a classroom community where every child is valued. This guide provides insights on how to create an environment that will help a student with TS thrive.</p>
            <p><Link as='a' to='#' className="ui violet basic button">Download</Link></p>
          </Grid.Column>
          <Grid.Column width={4}>
            <Header as='h4' content="For Principals" className="purple-header"/>
            <p>Principals and administrators play an important role in enhancing a TS student's experience. This guide covers ways to prepare teachers and staff for a TS student and offers suggestions on adapting the school environment for their special needs.</p>
            <p><Link as='a' to='#' className="ui violet basic button">Download</Link></p>
          </Grid.Column>
          <Grid.Column width={4}>
            <Header as='h4' content="For Counselors" className="purple-header"/>
            <p>For students with TS the school counselor if often the link between teachers, administrators and parents. This guide contains suggestions on how to establish a strong relationship with a TS student and help them adapt to the learning environment. </p>
            <p><Link as='a' to='#' className="ui violet basic button">Download</Link></p>
          </Grid.Column>
          <Grid.Column width={4}>
            <Header as='h4' content="For Support Staff" className="purple-header"/>
            <p>Adult support staff both in and out of school come into contact with TS students outside of the classroom. This guide provides suggestions on dealing with TS behaviors for bus drivers, coaches, and other adult leaders. </p>
            <p><Link as='a' to='#' className="ui violet basic button">Download</Link></p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8} style={{ borderRight: 'solid 1px #d7d7d7'}}>
            <Header as='h2' content="Links" />
            <p><strong>National Institutes of Health</strong><br/><a target="_blank" href="http://ninds.nih.gov/distorders/tourette/tourette.htm">http://ninds.nih.gov/distorders/tourette/tourette.htm</a></p>
            <p>Get a full TS fact sheet at<br/><a target="_blank" href="http://www.ninds.nih.gov/disorders/tourette/detail_tourette.htm#158843231">National Institute of Health Fact Sheet</a></p>
            <p>
              <strong>Kids Health</strong><br/>
              website with resources for kids and parents Includes information and background on TS<br/>
              <a href="http://kidshealth.org">http://kidshealth.org</a>
            </p>
            <p>
              <strong>Neuroscience for Kids:</strong><br/>
              Washington University faculty page on neuroscience
              Includes information on TS inclduing history, facts, diagnosis,
              causes and famous people with TS<br/>
              <a target="_blank" href="http://faculty.washington.edu/chudler/neurok.html">http://faculty.washington.edu/chudler/neurok.html</a>
            </p>
            <p><strong>National TSA</strong><br/><a target="_blank" href="http://tsa-usa.org/">National Tourette Syndrome Association online</a></p>
            <p><strong>Brad Cohen Tourette Foundation</strong><br/><a href="http://bradcohentourettefoundation.com">About BCTF</a></p>
            <p><strong>Joshua Center</strong><br/><a href="http://www.joshuacenter.com/index.htm" target="_blank">www.joshuacenter.com</a></p>
          </Grid.Column>
          <Grid.Column width={8}>
            <Header as='h2' content="Support the Foundation" />
            <p>Your donation to the Jim Eisenreich Foundation will help us reach more kids and families affected by TS.</p>
            <p>Please send your donation to:</p>
            <p>
              Jim Eisenreich Foundation<br/>
              Post Office Box 953<br/>
              Blue Springs, MO 64013
            </p>
            <p>Thank you for supporting our work with children suffering from Tourette Syndrome!!</p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  </Layout>
)

export default Resources
